import { Fragment, createRef, h } from "preact";
import { SHOPPING_CART_EVENTS } from "./shopping-cart-constants";
import { getBaseBuilderUrl } from "../Helpers/subscription-builder.helpers";
import { ShoppingCartTransferModel } from "../shop-types";

export interface AddServiceButtonModel {
  blockid: string;
  sessionid: string;
  classname?: string;
  buttonclassname?: string;
  buttontext?: string;
  ariabuttonlabel?: string;
  dataid?: string;
  productname: string;
  productpage: "plp" | "pdp";
}

const setSubscriptionCardZIndexes = () => {
  const subscriptionCardElements = document.querySelectorAll<HTMLElement>(".subscription-card");
  if (subscriptionCardElements.length === 0) return;

  try {
    let zIndex = subscriptionCardElements.length + 1;

    for (const subscriptionCardElement of subscriptionCardElements) {
      --zIndex;
      subscriptionCardElement.style.zIndex = zIndex.toString();
    }
  } catch (error) {
    console.warn("Error setting z-index on subscription cards: ", error);
  }
};

export const AddOfferingButton = () => {
  // can not use anonumous funksjon her - linter fail with message: Component definition is missing display name react/display-name
  const buildButton = ({
    blockid,
    sessionid,
    buttontext,
    classname,
    buttonclassname,
    ariabuttonlabel,
    dataid,
    productname,
    productpage,
  }: AddServiceButtonModel) => {
    const linkButtonRef = createRef<HTMLButtonElement>();
    const buttonId = `${productname.replace(" ", "").toLowerCase()}-cart-btn`;

    const addToCart = () => {
      const sendModel: ShoppingCartTransferModel = {
        blockId: blockid,
        sessionId: sessionid,
        openButton: linkButtonRef.current,
        openFromShoppingCart: false,
        update: false,
        baseUrl: getBaseBuilderUrl(),
        adding: true,
        productPage: productpage,
        preselectServiceId: "",
      };

      document.dispatchEvent(new CustomEvent(SHOPPING_CART_EVENTS.OPEN_SERVICE_SELECTOR_VIEW, { detail: sendModel }));
    };

    setSubscriptionCardZIndexes();

    return (
      <Fragment>
        <span className={`${classname ? classname : ""}`}>
          <button
            type="button"
            id={buttonId}
            className={buttonclassname}
            onClick={addToCart}
            ref={linkButtonRef}
            aria-label={ariabuttonlabel}
            data-id={dataid}
            data-enable-cta-click-tracking
            data-cta-tracking-category="legg_til"
          >
            {buttontext}
          </button>
        </span>
      </Fragment>
    );
  };
  return buildButton;
};
