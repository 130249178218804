import { create } from "zustand";
import { persist } from "zustand/middleware";
import type {
  AdditionalServices,
  MbbBundle,
  MbbPrices,
  Offering,
  ShoppingCartStoreOptions,
  Subscription,
  VoicePrices,
  VoiceShopStoreStateModel,
} from "./shop-types";
import { uuid } from "@shared/Utils";
import { sumActiveSubscriptionServices } from "./Helpers/shopping-cart-utils";
import { sendGA4Event } from "@shared/Analytics/ecommerce/mbb/b2c/mbb-b2c-analytics";
import type { ProductBlockDataModel } from "./Helpers/subscription-builder.types";
import { EcommerceEvents } from "@shared/Analytics/ecommerce/voice/voice-analytics-types";

const trackToCartVoice = (
  sub: Subscription,
  event: EcommerceEvents.TrackAddToCart | EcommerceEvents.TrackRemoveFromCart,
): void => {
  document.dispatchEvent(
    new CustomEvent(event, {
      detail: { offeringIds: offeringIdsToTrack(sub), productPage: sub.productPage },
    }),
  );
};
const trackAddToCartVoice = (sub: Subscription): void => trackToCartVoice(sub, EcommerceEvents.TrackAddToCart);

const trackRemoveFromCartVoice = (sub: Subscription): void =>
  trackToCartVoice(sub, EcommerceEvents.TrackRemoveFromCart);

const offeringIdsToTrack = ({ planId, additionalServices }: Subscription): string[] => {
  const addonOfferingIds = additionalServices.filter((s) => s.selected).map((s) => s.serviceId);
  return [planId, ...addonOfferingIds];
};

const trackToCartAddonsOnlyVoice = (
  offeringId: string,
  productPage: "plp" | "pdp",
  event: EcommerceEvents.TrackAddToCart | EcommerceEvents.TrackRemoveFromCart,
): void => {
  document.dispatchEvent(
    new CustomEvent(event, {
      detail: { offeringIds: [offeringId], productPage: productPage },
    }),
  );
};

const trackAddToCartAddonsOnlyVoice = (offeringId: string, productPage: "plp" | "pdp"): void =>
  trackToCartAddonsOnlyVoice(offeringId, productPage, EcommerceEvents.TrackAddToCart);

const trackRemoveFromCartAddonsOnlyVoice = (offeringId: string, productPage: "plp" | "pdp"): void =>
  trackToCartAddonsOnlyVoice(offeringId, productPage, EcommerceEvents.TrackRemoveFromCart);

const setPreselected = (serviceId: string, currentServiceId: string, preselected: boolean): boolean => {
  if (serviceId) {
    return serviceId === currentServiceId;
  }
  return preselected;
};

export const MapProductDataToSubScription = (
  model: ProductBlockDataModel | undefined,
  activeServiceId: string,
): Subscription => {
  if (!model) {
    throw new Error("ProductBlockDataModel is undefined in MapProductDataToSubScription in shop-cart.store.ts");
  }

  return {
    offeringType: "Voice",
    blockId: model.blockId,
    builderUrl: "",
    offeringId: uuid(),
    planId: model.productId,
    price: model.price,
    pricenocampaign: model.campaignPrice,
    showExternalServiceDialog: model.showExternalServiceDialog,
    name: model.productName,
    additionalServices: model.subscriptionServices.map((ser) => {
      const service: AdditionalServices = {
        price: ser.price,
        serviceId: ser.productId,
        selected: setPreselected(activeServiceId, ser.productId, ser.preselected),
        title: ser.title,
      };
      return service;
    }),
    productPage: model.productPage,
  };
};

export const ShoppingCartStore = ({
  cookieJar,
  namespace,
  isBusiness,
  isMbb,
  maxOrders,
  shippingPriceMbb,
}: ShoppingCartStoreOptions) => {
  const store = create<VoiceShopStoreStateModel>()(
    persist(
      (set, get) => ({
        offerings: [],
        updateService: (productId: string, serviceId: string, selected: boolean) => {
          set((state) => ({
            offerings: state.offerings.map((x) =>
              x.offeringId === productId
                ? {
                    ...x,
                    additionalServices: (x as Subscription).additionalServices.map((ser) =>
                      ser.serviceId === serviceId
                        ? {
                            ...ser,
                            selected: selected,
                          }
                        : ser,
                    ),
                  }
                : x,
            ),
          }));

          const trackFunction = selected ? trackAddToCartAddonsOnlyVoice : trackRemoveFromCartAddonsOnlyVoice;
          const sub = get().getOffering(productId) as Subscription;

          trackFunction(serviceId, sub.productPage);
        },
        updateServiceAndDeselectOthers: (productId: string, serviceId: string, selected: boolean): void => {
          const currentServiceId = (
            get().offerings.find((x) => x.offeringId === productId) as Subscription
          ).additionalServices.find((s) => s.selected).serviceId;
          if (currentServiceId !== serviceId) {
            const sub = get().getOffering(productId) as Subscription;

            trackRemoveFromCartAddonsOnlyVoice(currentServiceId, sub.productPage);
            trackAddToCartAddonsOnlyVoice(serviceId, sub.productPage);
          }

          set((state) => ({
            offerings: state.offerings.map((x) =>
              x.offeringId === productId
                ? {
                    ...x,
                    additionalServices: (x as Subscription).additionalServices.map((ser) =>
                      ser.serviceId === serviceId
                        ? {
                            ...ser,
                            selected: selected,
                          }
                        : { ...ser, selected: false },
                    ),
                  }
                : x,
            ),
          }));
        },
        addVoiceOffering: (model: Subscription) => {
          model.offeringType = "Voice";
          model.offeringId = uuid();
          set((state) => ({ offerings: [...state.offerings, model] }));

          trackAddToCartVoice(model);
        },
        addMbbOffering: (model: MbbBundle) => {
          model.offeringType = "MBB";
          model.offeringId = uuid();
          set((state) => ({ offerings: [...state.offerings, model] }));

          sendGA4Event("add_to_cart", model);
        },
        getOffering: (id: string) => {
          return get().offerings.find((s) => s.offeringId === id);
        },
        removeOffering: (id: string) => {
          const offeringstoRemove = get().offerings.find((x) => x.offeringId === id);

          set((state) => ({ offerings: state.offerings.filter((x) => x.offeringId !== id) }));

          if (isMbb) {
            sendGA4Event("remove_from_cart", offeringstoRemove as MbbBundle);
          } else {
            trackRemoveFromCartVoice(offeringstoRemove as Subscription);
          }
        },
        getPrice: () => {
          const offeringsList = get().getOfferings();
          if (!isMbb) {
            const sum: VoicePrices = {
              total: offeringsList.reduce((sum, elm) => {
                const subscription = elm as Subscription;
                return (
                  sum +
                  (subscription.pricenocampaign > 0 ? subscription.pricenocampaign : subscription.price) +
                  sumActiveSubscriptionServices(subscription?.additionalServices)
                );
              }, 0),
            };
            return sum;
          } else {
            const totalSum = offeringsList.reduce((sum, elm) => {
              return sum + (elm as MbbBundle).monthlyprice;
            }, 0);
            const prices: MbbPrices = {
              total: totalSum,
              shippingPrice: shippingPriceMbb,
              totalAfterCampaign: offeringsList.reduce(
                (sum, off) => sum + (off as MbbBundle).monthlypricenocampaign,
                0,
              ),
              totalUpfront:
                offeringsList.reduce((sum, off) => sum + (off as MbbBundle).routerprice, 0) + shippingPriceMbb,
            };

            return prices;
          }
        },
        numberOfProducts: () => {
          return get().getOfferings().length;
        },
        getOfferings: (): Offering[] => {
          if (isMbb) {
            return (get().offerings as MbbBundle[]).filter((x) => x.isbusiness === isBusiness);
          }
          return get().offerings;
        },
        getMaxOrders: () => {
          return maxOrders;
        },
        isMbb: () => {
          return isMbb;
        },
        isBusiness: () => {
          return isBusiness;
        },
      }),
      {
        name: namespace,
        storage: cookieJar,
      },
    ),
  );

  return store;
};
